import { useCallback, useMemo, useState } from 'react'
import { getVisitInfo } from '@ucheba/utils/hooks/useVisitsTracker'
import { SCHOOL_SITE_NAME } from '@ucheba/utils/constants/core'
import {
  clientProductsRequestsSelectors,
  clientProductsRequestsThunks,
} from '@ucheba/store/client-products/requests'
import { useDispatch, useStore } from 'react-redux'
import { EFunnels } from '@ucheba/utils/types/funnels'
import { EProductTypes } from '@ucheba/utils/types/productTypes'
import Img3 from '../assets/img__3.png'
import Img2 from '../assets/img__2.png'
import Img1 from '../assets/img__1.png'
import { ETypeRequest } from '../../../pages/for-abiturients/ege/index/types'
import {
  useEdPartnersClickId,
  useLovkoProClickId,
  useReferralCode,
  useSpecialOffer,
} from '../../AppliactionForm/bll'

interface IUseLidMagnetForm {
  (
    funnel: EFunnels,
    productType: EProductTypes,
    requestTarget: ETypeRequest,
    onSubmitForm?: () => void,
    onCheckedCode?: () => void
  ): {
    getImg: (key: number) => string
    initialValues: {
      name: string
      phone: string
    }
    onSubmit: (values: any) => void
    onPhoneConfirmSubmit: (code: string) => void
    needConfirmPhone: boolean
    onPhoneConfirmClose: () => void
    needShowMessengers: boolean
    onMessengersDialogClose: () => void
    phone: string | null
    isLoading: boolean
  }
}

export const useLidMagnetForm: IUseLidMagnetForm = (
  funnel,
  productType,
  requestTarget,
  onSubmitForm,
  onCheckedCode
) => {
  const dispatch = useDispatch()
  const [needConfirmPhone, setNeedConfirmPhone] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [needShowMessengers, setNeedShowMessengers] = useState(false)
  const [currentFormValues, setCurrentFormValues] = useState<{
    name: string
    phone: string
  } | null>(null)
  const store = useStore()
  const getImg = useCallback((key: number) => {
    switch (key) {
      case 0:
        return Img1
      case 1:
        return Img2
      case 2:
        return Img3
      default:
        return null
    }
  }, [])

  const initialValues = useMemo(() => {
    return {
      name: '',
      phone: '',
    }
  }, [])

  const lovkoProClickId = useLovkoProClickId()
  const edPartnersClickId = useEdPartnersClickId()
  const specialOfferCode = useSpecialOffer()
  const referralCode = useReferralCode('egeReferralCode')

  const sendRequest = useCallback(
    async (values: any, code: string | null): Promise<any> => {
      setIsLoading(true)
      const data = {
        funnel,
        productType,
        requestTarget,
        referralCode,
        specialOfferCode,
        code,
        phone: values.phone,
        name: values.name,
        visit: getVisitInfo(SCHOOL_SITE_NAME),
        lovkoProClickId,
        edPartnersClickId,
      }
      const result = await dispatch(clientProductsRequestsThunks.send({ data }))
      setIsLoading(false)
      return result
    },
    [
      currentFormValues,
      funnel,
      productType,
      requestTarget,
      referralCode,
      specialOfferCode,
      setIsLoading,
      dispatch,
      edPartnersClickId,
      lovkoProClickId,
    ]
  )

  const onSubmit = useCallback(
    async (values) => {
      setCurrentFormValues(values)
      const clientProductsRequestsEntity = await sendRequest(values, null)
      console.log(clientProductsRequestsEntity)
      const error = clientProductsRequestsSelectors.error(store.getState())
      console.log(error)
      if (!error) {
        if (onSubmitForm) {
          onSubmitForm()
        }

        if (!clientProductsRequestsEntity?.payload?.phoneConfirmed) {
          // Если нужно подтвердить телефон
          setNeedConfirmPhone(true)
        } else {
          // Если телефон подтвержден
          setNeedShowMessengers(true)
        }
      }
    },
    [sendRequest, store, setCurrentFormValues, onSubmitForm]
  )

  const onMessengersDialogClose = useCallback(() => {
    setNeedShowMessengers(false)
  }, [setNeedShowMessengers])

  const onPhoneConfirmSubmit = useCallback(
    async (code: string) => {
      await sendRequest(currentFormValues, code)
      const error = clientProductsRequestsSelectors.error(store.getState())
      console.log(error)
      if (!error) {
        if (onCheckedCode) {
          onCheckedCode()
        }
        setNeedShowMessengers(true)
        setNeedConfirmPhone(false)
      }
    },
    [sendRequest, currentFormValues, store, onCheckedCode]
  )

  const onPhoneConfirmClose = useCallback(() => {
    setNeedConfirmPhone(false)
  }, [setNeedConfirmPhone])

  return {
    getImg,
    initialValues,
    onSubmit,
    onPhoneConfirmSubmit,
    onPhoneConfirmClose,
    needConfirmPhone,
    needShowMessengers,
    onMessengersDialogClose,
    phone: currentFormValues?.phone || null,
    isLoading,
  }
}
