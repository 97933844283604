import { EColor, EFontSize } from '@ucheba/utils/helpers/styles/variables'
import { IPropChildren } from '@ucheba/utils/helpers/components/prop.types'
import { EFunnels } from '@ucheba/utils/types/funnels'
import { EProductTypes } from '@ucheba/utils/types/productTypes'
import { ETypeRequest } from '@ucheba/utils/types/typeRequest'

export interface ILidMagnetFormProps extends IPropChildren {
  title: string
  list?: string[]
  text?: string
  size?: 'small' | 'large'
  funnel: EFunnels
  productType: EProductTypes
  requestTarget: ETypeRequest
  onSubmitForm?: () => void
  onCheckedCode?: () => void
  withMessengers?: boolean
  color?: keyof typeof EColor
  overTitle?: string | null
  buttonText?: string | null
  imgUrl?: string | null
  titleSize?: keyof typeof EFontSize
  showInputs?: boolean
}

export interface IPhoneConfirmDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (code: string) => void
  phone: string
  isLoading: boolean
  onCheckedCode?: () => void
}

export interface IMessengerDialogProps {
  open: boolean
  onClose: () => void
}

export enum EButtonColorByTheme {
  gold = 'orange',
  navy = 'gold',
  blue = 'white',
  lime = 'emerald',
  rose = 'orange',
  plum = 'gold',
}

export enum EInputsColor {
  navy = 'white50',
  plum = 'white50',
  blue = 'white50',
}
