import { useDispatch, useSelector } from 'react-redux'
import {
  authPhoneCodeActions,
  authPhoneCodeSelectors,
  authPhoneCodeThunks,
} from '@ucheba/store/auth/phone/code'
import { useCallback, useEffect, useState } from 'react'
import { useTimer } from '@ucheba/utils/hooks/useTimer'
import { ELoading } from '@ucheba/store/utils/response/types'
import { codeLength } from '../constants'

export const usePhoneConfirmDialog = (
  phone: string,
  onSubmit: (code: string) => void
): any => {
  const dispatch = useDispatch()
  const timout = useSelector(authPhoneCodeSelectors.timeout)
  const sendCodeLoading = useSelector(authPhoneCodeSelectors.loading)
  const [error, setError] = useState<string | null>(null)
  /* Получаем таймер */
  const { timeLeft, isTimeLeft } = useTimer({
    seconds: timout,
    secondsByMinutes: true,
  })

  const onInputCode = useCallback(
    (_, codeValue) => {
      if (codeValue.length === codeLength) {
        onSubmit(codeValue)
      }
      setError(null)
    },
    [onSubmit]
  )
  /* Когда заканчивается время, ресетим его в сторе */
  useEffect(() => {
    if (isTimeLeft) {
      dispatch(authPhoneCodeActions.setTimeoutValue(0))
    }
  }, [dispatch, isTimeLeft])

  /* Метод запроса нового кода */
  const getNewCode = useCallback(() => {
    dispatch(authPhoneCodeThunks.sendCode({ data: { phone } }))
  }, [dispatch, phone])

  /* Запрашиваем код при инициализации */
  useEffect(() => {
    if (phone && sendCodeLoading === ELoading.idle) {
      getNewCode()
    }
  }, [getNewCode, phone, sendCodeLoading])

  return {
    timeLeft,
    isTimeLeft,
    onInputCode,
    getNewCode,
    error,
  }
}
