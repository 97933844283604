import React, { FC, memo } from 'react'
import { ConditionalWrapper } from '@ucheba/utils/helpers/components'
import SC from '../base/Tooltip.styles'
import { ITooltipProps } from '../types'
import { Text } from '../../Text/desktop'

const { Block, Wrapper, Content } = SC

/** Тултип */
const Tooltip: FC<ITooltipProps> = (props) => {
  const { content, position, width, children, html, ...otherProps } = props

  return (
    <Block {...otherProps}>
      <Wrapper width={width} position={position}>
        <Content>
          <ConditionalWrapper
            condition={!!html}
            wrapper={(childrenContent) => <Text html={html}>{childrenContent}</Text>}
          >
            {content}
          </ConditionalWrapper>
        </Content>
      </Wrapper>

      <>{children}</>
    </Block>
  )
}

export { Tooltip }
export default memo(Tooltip)
